import React from "react";
import { Routes, Route } from "react-router-dom";
// import { connect } from "react-redux";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Appointment from "./pages/Appointment/Appointment";
import SelectApplication from "./pages/SelectApplication/SelectApplication";

const AppRoutes = () => {
  return (
    <main>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route
          exact
          path='/select-application'
          element={<SelectApplication />}
        />
        <Route exact path='/apartment-registration' element={<Appointment />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </main>
  );
};

export default AppRoutes;
