import "./styles.css";
import React from "react";
import { colors } from "../../styles/styles";
import { Box, ButtonBase } from "@mui/material";
import Logo from "../Logo/Logo";
import SecurityIcon from "@mui/icons-material/Security";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CallIcon from "@mui/icons-material/Call";
import { FormattedMessage } from "react-intl";

export default function Footer() {
  const FooterItem_config = { fontSize: "5rem" };

  function FooterItem(props) {
    return (
      <ButtonBase className='footer-item'>
        <span>{props.img}</span>
        <span style={{ marginLeft: "0.5rem", fontSize: "1rem" }}>
          {props.label}
        </span>
      </ButtonBase>
    );
  }

  return (
    <Box
      style={{
        backgroundColor: colors.bg_dark,
        height: "20vh",
        // borderTopLeftRadius: "2rem",
        // borderTopRightRadius: "2rem",
        padding: "2rem 4rem 4rem 4rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <FooterItem
          img={<SecurityIcon style={FooterItem_config} />}
          label={<FormattedMessage id='Privacy Policy' />}
        />
        <FooterItem
          img={<GroupAddIcon style={FooterItem_config} />}
          label={<FormattedMessage id='Follow Us' />}
        />
        <FooterItem
          img={<CallIcon style={FooterItem_config} />}
          label={<FormattedMessage id='Contact' />}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <span style={{ marginRight: "3px" }}>© 2022</span>{" "}
        <Logo fontSize='1rem' />
      </div>
    </Box>
  );
}
