import "./styles.css";
import React from "react";
import { Box, Grid } from "@mui/material";
import { colors } from "../../styles/styles";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { Card } from "../../components/Card/Card";
import Logo from "../../components/Logo/Logo";
import useMediaQuery from "@mui/material/useMediaQuery";
import HomeIcon from "../../assets/icons/HomeIcon";
import CardIcon from "../../assets/icons/CardIcon";
import DriversLicenseIcon from "../../assets/icons/DriversLicenseIcon";

export default function Section2() {
  const navigate = useNavigate();

  const sm = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        marginTop: "70px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: colors.black,
        overflow: "hidden",
        minHeight: "100vh",
      }}
      style={{ padding: "0 4rem 4rem 4rem" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: sm ? "100%" : "30%",
          textAlign: "center",
          marginBottom: "5rem",
        }}
      >
        <span style={{ fontSize: "1.5rem", fontWeight: 500 }}>
          At <Logo fontSize='inherit' />, we take care of everything for you
        </span>
      </div>

      <Grid container spacing={!sm ? 10 : 5}>
        <Grid
          item
          xs={12}
          md={4}
          data-aos='fade-up'
          data-aos-delay='200'
          data-aos-duration='1000'
        >
          <Card
            title='Apartment Registration'
            description='Streamline your apartment registration process with our efficient service. Get organized and save time.'
            icon={<HomeIcon size='6rem' color={colors.red} />}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          data-aos='fade-up'
          data-aos-delay='400'
          data-aos-duration='1000'
        >
          <Card
            title='EU Blue Card'
            description='Get your EU Blue Card appointment with ease. Book now and simplify your visa process.'
            icon={<CardIcon size='6rem' color={colors.red} />}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          data-aos='fade-up'
          data-aos-delay='600'
          data-aos-duration='1000'
        >
          <Card
            title="Driver's License"
            description="Book your driver's license appointment with ease. Secure your spot and simplify the process."
            icon={<DriversLicenseIcon size='6rem' color={colors.red} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
