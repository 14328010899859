import "./styles.css";
import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { colors } from "../../styles/styles";
import { FormattedMessage } from "react-intl";
import relax from "../../assets/calendar.webp";
import { useNavigate } from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Section1() {
  const navigate = useNavigate();

  const sm = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        paddingTop: "70px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: colors.black,
        overflow: "hidden",
        minHeight: "90vh",
        backgroundColor: colors.bg_light,
      }}
    >
      <Grid container spacing={2} style={{ padding: sm ? "0 1rem" : "0 3rem" }}>
        <Grid
          item
          sm={12}
          md={6}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            paddingRight: "6rem",
          }}
        >
          <div
            style={{
              fontSize: "4rem",
              lineHeight: "4rem",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "5rem" }}>
              <FormattedMessage id='Hassle Free' />
            </span>
            <br />
            <span>
              <FormattedMessage id='Appointment Booking' />
            </span>
          </div>

          <div style={{ marginTop: "1.5rem" }}>
            <span>
              <FormattedMessage id='Your one-stop solution for appointment booking at the citizens office' />
            </span>
          </div>
          <Button
            className='btn-book-an-appointment'
            onClick={() => {
              navigate("/select-application");
            }}
          >
            <FormattedMessage id='Book an appointment now' />!
          </Button>
        </Grid>
        <Grid item sm={12} md={5}>
          <img alt='calendar' src={relax} />
        </Grid>
      </Grid>
    </Box>
  );
}
