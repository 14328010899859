import React, { useState, useEffect } from "react";
import { Grid, FormControl } from "@mui/material";
import ReactDatePicker from "../../components/ReactDatePicker/ReactDatePicker";
import InputField from "../../components/InputField/InputField";
import DropDown from "../../components/DropDown/DropDown";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";

function ApartmentRegistration() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());
  const [applicants, setApplicants] = useState(1);
  const [nationality, setNationality] = useState("");
  const [hasFamily, setHasFamily] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [applicationType, setApplicationType] = useState("");
  const [visaType, setVisaType] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");

  const handleApplicantsChange = (event) => {
    setApplicants(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleNationalityChange = (event) => {
    setNationality(event.target.value);
  };

  const handleHasFamilyChange = (event) => {
    setHasFamily(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleEmailChange = (event) => {
    setGender(event.target.value);
  };

  const handleApplicationTypeChange = (event) => {
    setApplicationType(event.target.value);
  };

  const handleVisaTypeChange = (event) => {
    setVisaType(event.target.value);
  };

  const handleAppointmentTimeChange = (event) => {
    setAppointmentTime(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add code here to handle form submission
  };

  const countries = [
    "AF",
    "AL",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BY",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BA",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "BI",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "CF",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CG",
    "CD",
    "CK",
    "CR",
    "CI",
    "HR",
    "CU",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "EE",
    "ET",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GN",
    "GW",
    "GY",
    "HT",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IE",
    "IL",
    "IT",
    "JM",
    "JP",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KP",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "MX",
    "FM",
    "MD",
    "MC",
    "MN",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "NL",
    "NC",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "MK",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RU",
    "RW",
    "SH",
    "KN",
    "LC",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "SC",
    "SL",
    "SG",
    "SK",
    "SI",
    "SB",
    "SO",
    "ZA",
    "GS",
    "ES",
    "LK",
    "SD",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "SY",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "UA",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VE",
    "VN",
    "VG",
    "VI",
    "WF",
    "EH",
    "YE",
    "ZM",
    "ZW",
    "AX",
    "BQ",
    "CW",
    "GG",
    "IM",
    "JE",
    "ME",
    "BL",
    "MF",
    "RS",
    "SX",
    "SS",
    "XK",
  ];

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField
            onChange={handleFirstNameChange}
            autoComplete='fname'
            variant='outlined'
            required
            fullWidth
            id='firstName'
            autoFocus
            value={firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            onChange={handleLastNameChange}
            autoComplete='lname'
            name='lastName'
            variant='outlined'
            required
            fullWidth
            id='lastName'
            value={lastName}
          />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 99 }}>
          <FormControl fullWidth required>
            <ReactDatePicker value={birthDate} setValue={setBirthDate} />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField
            variant='outlined'
            onChange={handleEmailChange}
            autoComplete='email'
            name='email'
            required
            fullWidth
            id='email'
            value={email}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DropDown
            id='nationality'
            fullWidth
            required
            variant='outlined'
            labelId='nationality-select-label'
            value={nationality}
            onChange={handleNationalityChange}
            options={countries}
            isCountryList
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DropDown
            id='applicants'
            fullWidth
            required
            variant='outlined'
            labelId='applicants-select-label'
            value={applicants}
            onChange={handleApplicantsChange}
            options={[1, 2, 3, 4, 5, 6, 7, 8]}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DropDown
            id='withFamily'
            fullWidth
            required
            variant='outlined'
            labelId='with-family-select-label'
            value={hasFamily}
            onChange={handleHasFamilyChange}
            options={["yes", "no"]}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DropDown
            id='applicationType'
            fullWidth
            required
            variant='outlined'
            labelId='application-type-select-label'
            value={applicationType}
            onChange={handleApplicationTypeChange}
            options={[
              "Apply for a residence permit",
              "Residence permit - extended",
              "Transfer residence permit to a new passport",
              "Apply for settlement permit",
              "Travel document - reissue",
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DropDown
            id='residencePermit'
            fullWidth
            required
            variant='outlined'
            labelId='residence-permit-select-label'
            value={hasFamily}
            onChange={handleHasFamilyChange}
            options={["yes", "no"]}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <PhoneNumberInput required />
        </Grid>
      </Grid>
    </form>
  );
}
export default ApartmentRegistration;
