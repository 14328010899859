import "./styles.css";
import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Link,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { colors } from "../../styles/styles";
import { connect, useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../redux/actions/settings";
import { FormattedMessage } from "react-intl";
import Logo from "../Logo/Logo";
import { useNavigate, useLocation } from "react-router-dom";

const pages = [
  {
    label: "Home",
    route: "/",
  },
  {
    label: "Submit a Request",
    route: "/submit-a-request",
  },
  {
    label: "Q&A",
    route: "/q&a",
  },
  {
    label: "Contact Us",
    route: "/contact-us",
  },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Header(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.settings.lang);

  const navigate = useNavigate();

  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const languages = [
    {
      flag: "🇺🇸",
      code: "en",
      label: "English",
    },
    {
      flag: "🇩🇪",
      code: "de",
      label: "Deutsch",
    },
    {
      flag: "🇷🇺",
      code: "ru",
      label: "Русский",
    },
    {
      flag: "🇹🇷",
      code: "tr",
      label: "Türkçe",
    },
  ];

  const handleChange = (event) => {
    dispatch(setLanguage(event.target.value));
  };

  return (
    <AppBar
      position='sticky'
      elevation={0}
      style={{
        padding: "0 1rem",
        background: colors.bg_light,
        color: colors.black,
        overflow: "hidden",
      }}
    >
      <Container maxWidth='xl'>
        <Toolbar
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Logo
            fontSize='2rem'
            navigateTo={() => {
              navigate("/");
            }}
          />
          <Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size='large'
                aria-label='mobile-menu'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((pageName) => (
                  <MenuItem
                    key={pageName.label}
                    aria-label={`navigate to ${pageName.route}`}
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate(pageName.route);
                    }}
                  >
                    <Typography
                      textAlign='center'
                      style={{
                        color:
                          location.pathname === pageName.label && colors.red,
                        fontWeight:
                          location.pathname === pageName.label && "bold",
                      }}
                    >
                      {pageName.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  md: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  overflow: "hidden",
                },
              }}
            >
              {pages.map((pageName) => (
                <Link
                  aria-label={`Go to ${pageName.label}`}
                  key={pageName.label}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(pageName.route);
                  }}
                  className={`header-link ${
                    location.pathname === pageName.route && "active"
                  }`}
                  sx={{
                    color: colors.black,
                    display: "block",
                    mr: 4,
                  }}
                >
                  <FormattedMessage id={pageName.label} />
                </Link>
              ))}

              <FormControl className={"language-selector"} size='small'>
                <Select
                  labelId='language-selector-label'
                  id='language-selector'
                  value={selectedLanguage}
                  onChange={handleChange}
                  size='small'
                  disableUnderline
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                >
                  {languages.map((language) => (
                    <MenuItem
                      key={language.code}
                      value={language.code}
                      style={{ color: colors.black }}
                    >
                      <span style={{ marginRight: "4px" }}>
                        {language.flag}
                      </span>
                      {language.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Menu
                sx={{ mt: "45px" }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign='center'>{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const mapStateToProps = (state) => {
  return { lang: state.settings.lang };
};

export default connect(mapStateToProps, { setLanguage })(Header);
