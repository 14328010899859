import "./styles.css";
import React from "react";
import { Box, Link } from "@mui/material";
import { colors } from "../../styles/styles";
import error404 from "../../assets/error404.webp";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: colors.black,
        overflow: "hidden",
      }}
      style={{ padding: "0 4rem 4rem 4rem" }}
    >
      <div style={{ width: "300px", height: "300px", marginBottom: "3rem" }}>
        <img src={error404} alt='page-not-found' />
      </div>
      <span style={{ fontSize: "2.5rem", fontWeight: 700, color: colors.red }}>
        <FormattedMessage id='Page not found' />
      </span>
      <Link
        style={{ color: colors.black, cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <FormattedMessage id='Go back to Homepage' />
      </Link>
    </Box>
  );
}
