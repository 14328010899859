import rootReducer from "./reducers/index";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import promise from "redux-promise";
import rootSagas from "./sagas";

const sagaMiddleWare = createSagaMiddleware();
const store = createStore(
  rootReducer,
  applyMiddleware(promise, sagaMiddleWare)
);
sagaMiddleWare.run(rootSagas);

export default store;
