import React from "react";
import { TextField, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

function InputField({ value, onChange, ...props }) {
  return (
    <React.Fragment>
      <Typography>
        <FormattedMessage id={props.id} />
        <span>{props.required && "*"}</span>
      </Typography>
      <TextField {...props} onChange={onChange} value={value} size='small' />
    </React.Fragment>
  );
}
export default InputField;
