import "./styles.css";
import React from "react";
import InputField from "../InputField/InputField";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from "date-fns";
import * as _ from "lodash";

function ReactDatePicker({ value, setValue }) {
  const years = _.range(1950, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <InputField
      id='dateOfBirth'
      type='date'
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputComponent: ({ inputRef, ...other }) => (
          <DatePicker
            {...other}
            ref={inputRef}
            dateFormat='dd.MM.yyyy'
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <IconButton
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
            )}
            selected={value}
            onChange={(date) => setValue(date)}
          />
        ),
      }}
    />
  );
}
export default ReactDatePicker;
