import "./homepage-card.css";
import "./app-card.css";
import React from "react";
import { Card as MUICard, CardContent, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export function Card({ title, description, icon, onClick }) {
  return (
    <MUICard className={`homepage-card`} onClick={onClick}>
      <CardContent>
        {icon}
        <Typography gutterBottom variant='h5' component='h5'>
          <FormattedMessage id={title} />
        </Typography>
        <Typography variant='body2' color='textSecondary' component='p'>
          <FormattedMessage id={description} />
        </Typography>
      </CardContent>
    </MUICard>
  );
}

export function AppointmentCard({ title, backgroundIcon, onClick }) {
  return (
    <MUICard
      className={`app-card ${backgroundIcon} appointment-card`}
      onClick={onClick}
    >
      <CardContent>
        <p style={{ fontSize: "1.5rem", fontWeight: 600 }}>
          <FormattedMessage id={title} />
        </p>
      </CardContent>
    </MUICard>
  );
}
