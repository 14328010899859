import { SET_LANGUAGE } from "../actions/settings";

const initialState = {
  lang:
    localStorage.getItem("lang") === null ? "en" : localStorage.getItem("lang"),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      localStorage.setItem("lang", action.payload);
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
};
