function HomeIcon({ size: _, color: $, opacity: t, ...o }) {
  return (
    <svg
      {...o}
      fillOpacity={t}
      fill={$}
      height={_}
      width={_}
      version='1.1'
      id='_x32_'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <polygon
        class='st0'
        points='434.16 293.38 434.16 493.86 308.32 493.86 308.32 368.58 203.68 368.58 203.68 493.86 77.841 493.86 77.841 293.38 256 153.86'
      />

      <polygon
        class='st0'
        points='0 242.68 256 38.93 512 242.68 482.21 285.76 256 105.72 29.79 285.76'
      />

      <polygon
        class='st0'
        points='439.85 18.138 439.85 148.54 376.57 98.138 376.57 18.138'
      />
    </svg>
  );
}
export default HomeIcon;
