import "./styles.css";
import React from "react";
import { Box, Grid } from "@mui/material";
import { colors } from "../../styles/styles";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { AppointmentCard } from "../../components/Card/Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import HomeIcon from "@mui/icons-material/Home";

export default function SelectApplication() {
  const navigate = useNavigate();

  const sm = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        marginTop: "70px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: colors.black,
        overflow: "hidden",
        minHeight: "100vh",
      }}
      style={{ padding: "0 4rem 4rem 4rem" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginBottom: "5rem",
        }}
      >
        <span style={{ fontSize: "1.5rem", fontWeight: 800 }}>
          Select your Application type
        </span>
      </div>

      <Grid container spacing={!sm ? 20 : 5}>
        <Grid item xs={12} md={4}>
          <AppointmentCard
            title='Apartment Registration'
            backgroundIcon='home-icon'
            onClick={() => navigate("/apartment-registration")}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppointmentCard title='Blue Card' backgroundIcon='card-icon' />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppointmentCard
            title='Drivers License'
            backgroundIcon='driver-license-icon'
          />
        </Grid>
      </Grid>
    </Box>
  );
}
