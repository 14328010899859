import "./styles.css";
import React, { useState } from "react";
import {
  FormControl,
  MenuItem,
  TextField,
  Select,
  Typography,
} from "@mui/material";
import countriesList from "./countries";
import ReactCountryFlag from "react-country-flag";
import { FormattedMessage } from "react-intl";

const PhoneNumberInput = (props) => {
  const [selectedCode, setSelectedCode] = useState("DE");

  const handleCodeChange = (event) => {
    setSelectedCode(event.target.value);
  };

  const codeOptions = countriesList.map((code) => (
    <MenuItem key={code.code} value={code.code}>
      <ReactCountryFlag
        countryCode={code.code}
        style={{ fontSize: "1.5rem", marginRight: "4px" }}
      />
      <FormattedMessage id={code.code} /> ({code.dial_code})
    </MenuItem>
  ));

  const getDialCode = (selected) => {
    for (const item of countriesList) {
      if (item.code === selected) {
        return item.dial_code;
      }
    }
  };

  return (
    <div>
      <Typography>
        <FormattedMessage id='Phone Number' />
        <span>{props.required && "*"}</span>
      </Typography>
      <FormControl>
        <Select
          labelId='country-code-label'
          id='country-code-select'
          value={selectedCode}
          onChange={handleCodeChange}
          required
          variant='outlined'
          size='small'
          renderValue={(selected) => {
            return (
              <div>
                <ReactCountryFlag
                  countryCode={selected}
                  style={{ fontSize: "1.5rem", marginRight: "4px" }}
                />
                {`(${getDialCode(selected)})`}
              </div>
            );
          }}
        >
          {codeOptions}
        </Select>
      </FormControl>
      <TextField id='phone-number' required variant='outlined' size='small' />
    </div>
  );
};

export default PhoneNumberInput;
