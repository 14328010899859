import React from "react";
import { Box } from "@mui/material";
import Section1 from "./Section1";
import Section2 from "./Section2";

export default function Home() {
  return (
    <Box>
      <Section1 />
      <div class='custom-shape-divider-top-1675284082'>
        <svg
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 120'
          preserveAspectRatio='none'
        >
          <path
            d='M1200 120L0 16.48 0 0 1200 0 1200 120z'
            class='shape-fill'
          ></path>
        </svg>
      </div>
      <Section2 />
    </Box>
  );
}
