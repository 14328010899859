import "./styles.css";
import React from "react";
import { Box, TextField, Divider, Grid } from "@mui/material";
import { colors } from "../../styles/styles";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
import ApartmentRegistration from "./ApartmentRegistration";

export default function Appointment() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        minHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "center",
        color: colors.black,
        overflow: "hidden",
      }}
      style={{ padding: "0 4rem 4rem 4rem" }}
    >
      <Box className='appointment-form-container'>
        <div className='appointment-form-title'>
          <FormattedMessage id='Lets book your appointment' />
        </div>
        <Divider />
        <div className='appointment-form'>
          <ApartmentRegistration />
        </div>
      </Box>
      {/* <ReCAPTCHA sitekey='YOUR-SITE-KEY' /> */}
    </Box>
  );
}
