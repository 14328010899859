import "./App.css";
import React from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AppRoutes from "./routes";
import AOS from "aos";
import "aos/dist/aos.css";

const App = (props) => {
  React.useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <IntlProvider messages={require(`./translations/${props.lang}.json`)}>
      <Router>
        <Header />
        <AppRoutes />
      </Router>
      <Footer />
    </IntlProvider>
  );
};

const mapStateToProps = (state) => {
  return { lang: state.settings.lang };
};

export default connect(mapStateToProps, {})(App);
