import "./styles.css";
import * as React from "react";
import { Typography } from "@mui/material";
import { colors } from "../../styles/styles";

function Logo({ fontSize = "1rem", navigateTo }) {
  return (
    <Typography
      variant='h6'
      noWrap
      component='a'
      onClick={navigateTo}
      sx={{
        userSelect: "none",
        fontWeight: 700,
        textDecoration: "none",
        fontSize: fontSize,
        cursor: navigateTo && "pointer",
      }}
      className='logo'
    >
      Alle<span className='termin'>Termin</span>
    </Typography>
  );
}

export default Logo;
