import React from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ReactCountryFlag from "react-country-flag";

function Form({
  label,
  id,
  value,
  onChange,
  options,
  required,
  fullWidth,
  variant,
  isCountryList,
}) {
  return (
    <React.Fragment>
      <Typography>
        <FormattedMessage id={id} />
        <span>{required && "*"}</span>
      </Typography>
      <FormControl fullWidth={fullWidth} required={required} variant={variant}>
        <Select id={id} value={value} onChange={onChange} size='small'>
          <MenuItem value=''>
            <em>
              <FormattedMessage id={"none"} />
            </em>
          </MenuItem>
          {isCountryList
            ? options.map((item, idx) => {
                return (
                  <MenuItem value={item} key={idx}>
                    <ReactCountryFlag
                      countryCode={item}
                      style={{ fontSize: "1.5rem", marginRight: "4px" }}
                    />
                    <FormattedMessage id={item} />
                  </MenuItem>
                );
              })
            : options.map((item, idx) => {
                return (
                  <MenuItem value={item} key={idx}>
                    <FormattedMessage id={item} />
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}
export default Form;
