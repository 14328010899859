export const colors = {
  bg_light: "#ebe7e6",
  bg_dark: "#D2D2D2",
  black: "#2f3542",
  white: "#FFFFFF",
  blue: "#052892",
  blue_light: "#3C69FA",
  red: "#eb2d3f",
  red_light: "#ff6884",
  brown: "#D5A177",
  green: "#1BB57D",

  rgb: {
    bg_light: "235, 231, 230",
    bg_dark: "210, 210, 210",
    black: "47, 53, 66",
    white: "255, 255, 255",
    blue: "5, 40, 146",
    blue_light: "60, 105, 250",
    red: "235, 45, 63",
    red_light: "255, 104, 132",
    brown: "213, 161, 119",
    green: "27, 181, 125",
  },
};

export const button = {
  borderRadius: "10px",
  borderRadius_big: "1.5rem",
};

export const fontSize = {
  small: "",
  medium: "",
  large: "",
};
